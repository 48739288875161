/* eslint-disable max-lines-per-function */
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Button,
	CircularProgress,
	Grid,
	TextField
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import React, { useContext, useState } from 'react';
import { LANDING_PAGE_PLACEHOLDERS, TDR } from 'tdr-common';
import { updateRestaurantSettings } from '../../api/updateLandingPageSettings';
import { FirebaseContext } from '../../context/FirebaseContext';
import { RestaurantContext } from '../../context/RestaurantContext';
import { RestaurantSettingsFormProps, useStyles } from './AdminWidgets';

export const LandingPageConfig = ({ formData, setFormData }: RestaurantSettingsFormProps) => {
	const { selectedRestaurant } = useContext(RestaurantContext);
	const { firestore } = useContext(FirebaseContext);

	const [savingChanges, setSavingChanges] = useState(false);
	const classes = useStyles();

	const handleSaveChanges = async () => {
		setSavingChanges(true);

		try {
			await updateRestaurantSettings(firestore, selectedRestaurant.id, formData);
		}
		finally {
			setSavingChanges(false);
		}
	};

	return (
		<Accordion classes={{ root: classes.root }}>
			<AccordionSummary
				expandIcon={<ExpandMore />}
				classes={{ content: classes.summaryContent }}
			>
				<h1 className={classes.heading}>Landing Page</h1>
				<p className={classes.subheading}>
          			Configure the landing page copy
				</p>
			</AccordionSummary>
			<AccordionDetails
				style={{ display: 'flex', flexDirection: 'column' }}
			>
				<Grid container alignItems='center' spacing={4}>
					<Grid item xs={6}>
						<h2 style={{ margin: 0 }}>Tablz</h2>
						<p>Overrides the settings on either the default or flipped version</p>
						<TextField
							label='Title'
							variant='outlined'
							multiline
							InputLabelProps={{ shrink: true }}
							maxRows={4}
							placeholder={LANDING_PAGE_PLACEHOLDERS.tablz?.title}
							value={formData?.landingPage?.tablz?.title || ''}
							onChange={(evt) => {
								const newFormData = {
									...formData,
									landingPage: {
										...formData?.landingPage,
										tablz: {
											...formData?.landingPage?.tablz,
											title: evt.target.value
										}
									}
								} as TDR.RestaurantSettings;
								setFormData(newFormData);
							}}
						/>
						<TextField
							label='Description (138 characters max)'
							variant='outlined'
							minRows={2}
							multiline
							InputLabelProps={{ shrink: true }}
							inputProps={{ maxLength: 138 }}
							maxRows={4}
							placeholder={LANDING_PAGE_PLACEHOLDERS.tablz.description}
							value={formData?.landingPage?.tablz?.description || ''}
							onChange={(evt) => {
								const newFormData = {
									...formData,
									landingPage: {
										...formData?.landingPage,
										tablz: {
											...formData?.landingPage?.tablz,
											description: evt.target.value
										}
									}
								} as TDR.RestaurantSettings;
								setFormData(newFormData);
							}}
						/>
						<TextField
							label='Call To Action'
							variant='outlined'
							minRows={2}
							multiline
							InputLabelProps={{ shrink: true }}
							maxRows={4}
							placeholder={LANDING_PAGE_PLACEHOLDERS.tablz.callToAction}
							value={formData?.landingPage?.tablz?.callToAction || ''}
							onChange={(evt) => {
								const newFormData = {
									...formData,
									landingPage: {
										...formData?.landingPage,
										tablz: {
											...formData?.landingPage?.tablz,
											callToAction: evt.target.value
										}
									}
								} as TDR.RestaurantSettings;
								setFormData(newFormData);
							}}
						/>
					</Grid>
					<Grid item xs={6}>
						<h2 style={{ margin: 0 }}>Alternate</h2>
						<p>* Only Available On the RMS booking tile (not on the phone, email and other booking options)</p>
						<TextField
							label='Title'
							variant='outlined'
							multiline
							InputLabelProps={{ shrink: true }}
							maxRows={4}
							placeholder={LANDING_PAGE_PLACEHOLDERS.alternate?.title}
							value={formData?.landingPage?.alternate?.title || ''}
							onChange={(evt) => {
								const newFormData = {
									...formData,
									landingPage: {
										...formData?.landingPage,
										alternate: {
											...formData?.landingPage?.alternate,
											title: evt.target.value
										}
									}
								};
								setFormData(newFormData);
							}}
						/>
						<TextField
							label='Description (138 characters max)'
							minRows={2}
							variant='outlined'
							multiline
							InputLabelProps={{ shrink: true }}
							inputProps={{ maxLength: 138 }}
							maxRows={4}
							placeholder={LANDING_PAGE_PLACEHOLDERS.alternate.description}
							value={formData?.landingPage?.alternate?.description || ''}
							onChange={(evt) => {
								const newFormData = {
									...formData,
									landingPage: {
										...formData?.landingPage,
										alternate: {
											...formData?.landingPage?.alternate,
											description: evt.target.value
										}
									}
								} as TDR.RestaurantSettings;
								setFormData(newFormData);
							}}
						/>
						<TextField
							label='Call To Action'
							minRows={2}
							variant='outlined'
							multiline
							InputLabelProps={{ shrink: true }}
							maxRows={4}
							placeholder={LANDING_PAGE_PLACEHOLDERS.alternate.callToAction}
							value={formData?.landingPage?.alternate?.callToAction || ''}
							onChange={(evt) => {
								const newFormData = {
									...formData,
									landingPage: {
										...formData?.landingPage,
										alternate: {
											...formData?.landingPage?.alternate,
											callToAction: evt.target.value
										}
									}
								} as TDR.RestaurantSettings;
								setFormData(newFormData);
							}}
						/>
					</Grid>
					<Grid item xs={3} style={{ paddingTop: 0 }}>
						<Button
							disabled={savingChanges}
							classes={{
								root: classes.buttonRoot
							}}
							onClick={handleSaveChanges}
						>
							{savingChanges ? <CircularProgress /> : 'Save Changes'}
						</Button>
					</Grid>
					<Grid item xs={9}></Grid>
				</Grid>
			</AccordionDetails>
		</Accordion>
	);
};