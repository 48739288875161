import React, { useState } from 'react';
import { Button, Snackbar } from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';

const ReservationRMSCopyButton = ({ copy, title }: {copy: string, title: string}) => {
	const [open, setOpen] = useState(false);

	if (!copy) {
		return null;
	}

	const handleCopy = (event) => {
		event.stopPropagation();
		navigator.clipboard.writeText(copy);
		setOpen(true);
	};

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpen(false);
	};

	return (
		<>
			<Button
				variant="contained"
				color="secondary"
				style={{ maxWidth: '150px', marginRight:'20px' }}
				startIcon={<FileCopyIcon />}
				onClick={handleCopy}
			>
				{title}
			</Button>
			<Snackbar
				open={open}
				autoHideDuration={2000}
				onClose={handleClose}
				message={`Copied to clipboard: ${copy}`}
			/>
		</>
	);
};

export default ReservationRMSCopyButton;
