import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Button,
	Card,
	Chip,
	CircularProgress,
	TextField
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import SmsIcon from '@material-ui/icons/Sms';
import SmsFailedIcon from '@material-ui/icons/SmsFailed';
import { DateTime } from 'luxon';
import React, { Dispatch, useContext, useEffect, useState } from 'react';
import {
	MILLISECONDS_IN_A_SECOND,
	TDR,
	dateToString,
	formatPhoneNumber,
	isInThePast
} from 'tdr-common';
import { v4 as uuidv4 } from 'uuid';

import firebase from 'firebase/app';
import { FirebaseContext } from '../context/FirebaseContext';
import { ModifyReservationContext } from '../context/ModifyReservationContext';
import { TimeSlotProvider } from '../context/TimeSlotContext';
import { applyCommsDocChange } from '../helpers/applyCommsDocChange';
import { getLuxonFromFirestoreTimestamp } from '../helpers/getLuxonFromFirestoreTimestamp';
import luxonToFirebaseTimestamp from '../helpers/luxonToFirebaseTimestamp';
import Communication from './Communication';
import LargeGroupStatus from './LargeGroupStatus';
import ModalCancelledBooking from './ModalCancelledBooking';
import ModalEditGuest from './ModalEditGuest';
import ModalExpiredBooking from './ModalExpiredBooking';
import ModifyReservation from './ModifyReservation';
import ReservationDetails from './ReservationDetails';

export type ModalProps = {
	open: boolean;
	onClose: () => void;
}

type ReservationAccordionProps = {
	reservation: TDR.Reservation;
	setSelectedReservation: React.Dispatch<React.SetStateAction<TDR.Reservation>>;
	restaurant: TDR.Restaurant;
	table: TDR.Table;
	setIsModifyStatusOpen?: React.Dispatch<React.SetStateAction<boolean>>;
	setIsConfirmApprovalOpen?: React.Dispatch<React.SetStateAction<boolean>>;
	setIsConfirmDenialOpen?: React.Dispatch<React.SetStateAction<boolean>>;
	setIsProposeTimeOpen?: React.Dispatch<React.SetStateAction<boolean>>;
};

const ReservationAccordion = ({
	reservation: originalReservation,
	setSelectedReservation,
	restaurant,
	table,
	setIsModifyStatusOpen,
	setIsConfirmApprovalOpen,
	setIsConfirmDenialOpen,
	setIsProposeTimeOpen
}: ReservationAccordionProps) => {
	const { firestore } = useContext(FirebaseContext);
	const { setSelectedDate } = useContext(ModifyReservationContext);

	const [communications, setCommunications] = useState<TDR.Reservation.Communication[]>([]);
	const [reply, setReply]: [string, Dispatch<string>] = useState('');
	const [pendingReply, setSendingReply] =
    useState<TDR.Reservation.Communication<firebase.firestore.Timestamp>>(null);
	// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
	const [unsubFn, setUnsubFn] = useState<() => void>(null);

	const [editReservationModalOpened, setEditReservationModalOpened] =
    useState(false);
	const [isInThePastModalOpened, setIsInThePastModalOpened] = useState(false);
	const [isCancelledModalOpened, setIsCancelledModalOpened] = useState(false);
	const [modifyReservationDrawerOpened, setModifyReservationDrawerOpened] = useState(false);
	const reservation = { ...originalReservation, ...originalReservation.pendingChanges };

	const createdAt = getLuxonFromFirestoreTimestamp(reservation?.createdAt, restaurant?.timezone);
	const updatedAt = reservation.updatedAt ? getLuxonFromFirestoreTimestamp(reservation?.updatedAt, restaurant?.timezone): null;
	const isModified = createdAt && updatedAt ? createdAt.diff(updatedAt, 'minutes').minutes !== 0: false;


	function markCommsAsRead() {
		communications
			.filter((comm) => comm.status === TDR.Reservation.TkMessageStatus.Unread)
			.forEach((comm) => {
				firestore
					.collection('Communications')
					.doc(comm.id)
					.update({ status: TDR.Reservation.TwilioMessageStatus.Read });
			});
	}

	function unsubCommunications() {
		// don't mark communications as read until we close the accordion
		// otherwise, it's way the fuck too jumpy
		markCommsAsRead();

		setUnsubFn((unsubFn) => {
			if (unsubFn) {
				unsubFn();
			}
			return null;
		});
	}

	function subCommunications() {
		const query = firestore
			.collection('Communications')
			.where('reservationId', '==', reservation.id);

		setUnsubFn((unsubFn) => {
			if (unsubFn) {
				unsubFn();
			}

			return query.onSnapshot((snapshot) => {
				setCommunications((communications: TDR.Reservation.Communication[]) => {
					const newComms = [...communications];
					snapshot
						.docChanges()
						.forEach((change) => applyCommsDocChange(newComms, change));
					return newComms.sort(
						(a, b) => a?.timestamp?.seconds - b?.timestamp?.seconds
					);
				});
			});
		});
	}

	function sendReply() {
		// if we haven't actually got a message, or we're already in the process of sending, don't send
		if (reply.length === 0 || pendingReply) {
			return;
		}

		// disallow identical messages within the same two minutes (double-click guardrail)
		const now = new Date().getTime() / MILLISECONDS_IN_A_SECOND;
		const resendCooldown = 120;

		const alreadySent = communications.find(
			(comm) =>
				comm.body === reply &&
        Math.abs(comm.timestamp.seconds - now) <= resendCooldown
		);
		if (alreadySent) {
			return;
		}

		const communication: TDR.Reservation.Communication<firebase.firestore.Timestamp> = {
			id: uuidv4(),
			reservationId: reservation.id,
			to: formatPhoneNumber(reservation.phone),
			type: TDR.Reservation.CommunicationType.MiscToCustomer,
			status: TDR.Reservation.TkMessageStatus.Created,
			channel: TDR.Reservation.CommunicationChannel.Sms,
			body: reply,
			// timestamp  is going to get stomped when the message is actually sent,
			// which is fine - I still want to know when I *tried* to send it
			timestamp: luxonToFirebaseTimestamp(DateTime.local())
		};

		setSendingReply(communication);
		firestore
			.collection('Communications')
			.doc(communication.id)
			.set(communication);
	}


	// when we're done, clean everything up (when are we actually done? when the context unmounts? when is that?)
	useEffect(() => unsubCommunications, []);

	// when we see our pending message has been passed to twilio, remove it from Pending
	useEffect(() => {
		const savedPendingReply =
      pendingReply &&
      communications.find(
      	(comm) =>
      		comm.body === pendingReply.body &&
          comm.timestamp.seconds >= pendingReply.timestamp.seconds
      );

		if (
			savedPendingReply &&
      savedPendingReply.status !== TDR.Reservation.TkMessageStatus.Created
		) {
			setSendingReply(null);
		}
	}, [communications]);

	return (
		<>
			<ModalEditGuest
				open={editReservationModalOpened}
				onClose={() => setEditReservationModalOpened(false)}
				reservation={reservation}
			/>

			{modifyReservationDrawerOpened && (
				<TimeSlotProvider reservation={reservation} restaurant={restaurant}>
					<ModifyReservation
						open={modifyReservationDrawerOpened}
						onClose={() => setModifyReservationDrawerOpened(false)}
						reservation={reservation}
						table={table}
						restaurant={restaurant}
					/>
				</TimeSlotProvider>
			)}

			<ModalExpiredBooking
				open={isInThePastModalOpened}
				onClose={() => setIsInThePastModalOpened(false)}
			/>

			<ModalCancelledBooking
				open={isCancelledModalOpened}
				onClose={() => setIsCancelledModalOpened(false)}
			/>

			<Accordion
				className={[
					'reservation',
					reservation.hasNewCommunications ? 'new-communications' : '',
					reservation.hasFailedCommunications ? 'failed-communications' : '',
					reservation.pendingChanges ? 'large-group-modification' : ''
				].join(' ')}
				style={{ borderBottom: '1px solid rgba(255, 255, 255, 0.87)' }}
				onChange={(evt, expanded) =>
					expanded ? subCommunications() : unsubCommunications()
				}
			>
				<AccordionSummary expandIcon={<KeyboardArrowDownIcon />}>
					<Box
						display='flex'
						flexDirection='row'
						alignItems='center'
						justifyContent='space-between'
						width='100%'
					>
						<Box display='flex' flexDirection='row'>
							<Box display='flex' mr={2}>
								{restaurant?.name}
							</Box>

							<Box display='flex' mr={2}>
								{`${table?.internalName} (${table?.name})`}
							</Box>

							<Box display='flex' mr={2}>
								<Button
									style={{ textTransform: 'none' }}
									data-cy={`edit-${reservation.id}`}
									onClick={(event) => {
										event.stopPropagation();
										if (isInThePast(reservation)) {
											setIsInThePastModalOpened(true);
										}
										else if (
											reservation.status === TDR.Reservation.Status.Cancelled
										) {
											setIsCancelledModalOpened(true);
										}
										else {
											setSelectedDate(reservation.time.toDate());
											setModifyReservationDrawerOpened(true);
										}
									}}
								>
									{dateToString(reservation.time, reservation.timezone)}
								</Button>
							</Box>

							<Box display='flex' mr={2}>
								{reservation?.email}
							</Box>


							{ isModified && (
								<Chip
									label="Has Modifications"
									color="primary"
								/>
							)}

							{reservation.hasFailedCommunications ? (
								<SmsFailedIcon style={{ color: 'rgba(255, 255, 255, 0.87)' }} />
							) : reservation.hasNewCommunications ? (
								<SmsIcon style={{ color: 'rgba(255, 255, 255, 0.87)' }} />
							) : null}
						</Box>

						<Box
							display='flex'
							justifyContent='space-between'
							style={{ gap: '10px' }}
						>
							<Button
								variant='contained'
								color='primary'
								size='small'
								onClick={(event) => {
									event.stopPropagation();
									setEditReservationModalOpened(true);
								}}
								onFocus={(event) => event.stopPropagation()}
							>
                Edit
							</Button>

							<Button
								variant='contained'
								size='small'
								onClick={(event) => {
									event.stopPropagation();
									setSelectedReservation(reservation);
									setIsModifyStatusOpen(true);
								}}
								onFocus={(event) => {
									event.stopPropagation();
								}}
							>
								{reservation.status || 'LEGACY: NO STATUS'}
							</Button>

							{reservation.isLargeGroup && (
								<LargeGroupStatus
									reservation={originalReservation}
									setSelectedReservation={setSelectedReservation}
									setIsConfirmApprovalOpen={setIsConfirmApprovalOpen}
									setIsConfirmDenialOpen={setIsConfirmDenialOpen}
									setIsProposeTimeOpen={setIsProposeTimeOpen}
								/>
							)}
						</Box>
					</Box>
				</AccordionSummary>

				<AccordionDetails>
					<Box display='flex' flexDirection='column'>
						<ReservationDetails
							reservation={reservation}
							restaurant={restaurant}
							table={table}
						/>

						{communications.map((comm, index) => (
							<Communication
								key={index}
								comm={comm}
								firestore={firestore}
								restaurant={restaurant}
							/>
						))}

						<Card>
							<TextField
								label='Message'
								multiline
								maxRows={4}
								value={reply}
								onChange={(evt) => setReply(evt.target.value)}
								variant='outlined'
							/>

							<Button
								variant='contained'
								size='small'
								color='primary'
								disabled={!!pendingReply || reply?.length === 0}
								onClick={(event) => {
									event.stopPropagation();
									sendReply();
								}}
								onFocus={(event) => {
									event.stopPropagation();
								}}
							>
								{!pendingReply ? (
									'Send'
								) : (
									<>
										<span style={{ marginRight: '2em' }}>Sending</span>{' '}
										<CircularProgress />
									</>
								)}
							</Button>
						</Card>
					</Box>
				</AccordionDetails>
			</Accordion>
		</>
	);
};

export default ReservationAccordion;