/* eslint-disable max-lines-per-function */
import React, { useContext, useState, useEffect } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import isNull from 'lodash/isNull';
import without from 'lodash/without';
import uniq from 'lodash/uniq';
import isEmpty from 'lodash/isEmpty';
import toPairs from 'lodash/toPairs';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import noop from 'lodash/noop';
import omitBy from 'lodash/omitBy';
import isNumber from 'lodash/isNumber';
import isNaN from 'lodash/isNaN';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import { v4 as uuid } from 'uuid';
import slugify from 'slugify';
import {
	TextField,
	Link,
	Box,
	Button,
	Select,
	MenuItem,
	Container,
	IconButton,
	Typography,
	Switch,
	InputLabel,
	RootRef,
	List,
	ListItem,
	ListItemIcon,
	FormControlLabel,
	FormHelperText,
	Slider, CircularProgress
} from '@material-ui/core';
import {
	DeleteForever as DeleteIcon,
	AddCircle as AddIcon,
	KeyboardArrowDown as KeyboardArrowDownIcon,
	DragHandle,
	Help
} from '@material-ui/icons';

import { formatMonetaryValue, SECONDS_IN_AN_HOUR, TDR } from 'tdr-common';

import { RestaurantContext } from '../context/RestaurantContext';
import { TableContext } from '../context/TableContext';
import { FirebaseContext } from '../context/FirebaseContext';
import { ModalContext } from '../context/ModalContext';
import {
	MatterportContext,
	MatterportProvider
} from '../context/MatterportContext';
import { PricingPolicyContext } from '../context/PricingPolicyContext';

import Modal from './Modal';
import Notice from './Notice';
import PhoneEditor from './PhoneEditor';
import OverridesEditor from './OverridesEditor';
import Matterport from './Matterport';
import { ConditionEditor, ConditionHelpButton } from './ConditionEditor';
import { ClosedDates } from './ClosedDates';
import DietaryOptions from './DietaryRestrictions';

import cutoffsHelpDoc from '../markdown/cutoffs.md';
import PromoCodeEditor from './PromoCodeEditor';
import syncImages from '../api/syncImages';
import migrateRestaurantData from '../api/migrateRestaurantData';
namespace Props {
	export type PolicyGroupToggle = {
		policies: TDR.PricingPolicy[];
		group: string;
		activeGroups: string[];
		setGroup: (group: string, active: boolean) => void;
	};
	export type TimeBeforeEditor = {
		label: string;
		disabled?: boolean;
		cutoffType: 'create' | 'cancel' | 'update';
		cutoffs: TDR.ReservationCutoffs;
		onChange: (tb: TDR.TimeBefore) => void;
	};

	export type CutoffsArrayEditor = {
		restaurant: TDR.Restaurant;
		onChange: (cutoffs: TDR.ReservationCutoffs[]) => void;
	};
	export type CutoffEditor = {
		cutoffs: TDR.ReservationCutoffs;
		onChange?: (cutoff: TDR.ReservationCutoffs) => void;
		disabled?: boolean;
	};
}

const AdminRestaurants = () => {
	const { restaurants, setRestaurants, selectedRestaurantId /*allPartnerships*/ } =
    useContext(RestaurantContext);
	const { getTablesByRestaurant } = useContext(TableContext);
	const { firestore, token } = useContext(FirebaseContext);
	const { openModal, closeModal } = useContext(ModalContext);
	const [errorMsg, setErrorMsg] = useState<string>('');
	const [syncing, setSyncing] = useState<boolean>(false);

	const [activeRestaurant, setActiveRestaurant] =
    useState<TDR.Restaurant | null>(null);
	const { restaurantPolicies, taxPolicies, payoutPolicies } =
    useContext(PricingPolicyContext);
	const [tables, setTables] = useState<TDR.Table[] | null>(null);

	const getRestaurant = (id: string): TDR.Restaurant | null => {
		return (
			cloneDeep(restaurants.find((restaurant) => restaurant.id === id)) || null
		);
	};

	useEffect(() => {
		if (
			!isNull(selectedRestaurantId) &&
      (!activeRestaurant || selectedRestaurantId !== activeRestaurant.id)
		) {
			setActiveRestaurant(getRestaurant(selectedRestaurantId));
		}

		if (selectedRestaurantId) {
			getTablesByRestaurant(selectedRestaurantId).then(tables => {
				setTables(tables);
			});
		}
		else {
			setTables(null);
		}
	}, [selectedRestaurantId]);

	function setTax(taxId: string, active: boolean) {
		const taxes = activeRestaurant.taxes || [];
		const newTaxes = active ? uniq([...taxes, taxId]) : without(taxes, taxId);

		setActiveRestaurant(
			Object.assign({}, activeRestaurant, { taxes: newTaxes })
		);
	}

	function setPayout(payoutId: string, active: boolean) {
		const payouts = activeRestaurant.payouts || [];
		const newPayouts = active
			? uniq([...payouts, payoutId])
			: without(payouts, payoutId);

		setActiveRestaurant(
			Object.assign({}, activeRestaurant, { payouts: newPayouts })
		);
	}

	return (
		<Container maxWidth="xl">
			{!isEmpty(errorMsg) && (
				<Notice message={errorMsg} onClear={() => setErrorMsg('')} />
			)}
			<Box py={2} marginTop={'32px'}>
				<Box display="flex" flexDirection="row" width="100%">
					<Box display="flex" flexDirection="row" flexGrow={1}>
						<h2>Restaurants</h2>
						<IconButton
							onClick={() => {
								openModal(
									<MatterportProvider>
										<AddRestaurantModal
											onClose={() => closeModal()}
											onSave={(restaurant) => console.log(restaurant)}
										/>
									</MatterportProvider>
								);
							}}
						>
							<AddIcon />
						</IconButton>
					</Box>

					<RestaurantSelector />
					<Box ml={1}>
						<Button
							variant="contained"
							color="primary"
							className="next"
							disabled={isNull(activeRestaurant)}
							onClick={() => {
								setSyncing(true);
								syncImages(activeRestaurant.id).then(r => {
									console.log(r);
									setSyncing(false);
								});
							}}
						>
							{syncing ? <CircularProgress/> : 'Sync Images'}
						</Button>
					</Box>
					<Box ml={1}>
						<Button
							variant="contained"
							color="primary"
							className="next"
							disabled={isNull(activeRestaurant)}
							onClick={() => {
								setSyncing(true);
								migrateRestaurantData(token, activeRestaurant).then((res) => {
									setSyncing(false);
									if(res.error) {
										setErrorMsg(res.message);
									}
								});
							}}
						>
							{syncing ? <CircularProgress/> : 'Migrate Data'}
						</Button>
					</Box>

					<Box ml={1}>
						<Button
							variant="contained"
							color="primary"
							className="next"
							disabled={isNull(activeRestaurant)}
							onClick={() => {
								firestore
									.collection('Restaurants')
									.doc(activeRestaurant.id)
									.set(activeRestaurant, { merge: true });
								// Find and update the restaurant in the list of restaurants by id with new
								setRestaurants(
									restaurants.map((restaurant) =>
										restaurant.id === activeRestaurant.id
											? activeRestaurant
											: restaurant
									)
								);
							}}
						>
              Save
						</Button>
					</Box>
				</Box>

				{!isNull(activeRestaurant) && (
					<Box>
						<Box display="flex" flexDirection="row">
							<TextField
								label="Restaurant Name"
								placeholder="Restaurant Name"
								variant="outlined"
								InputLabelProps={{ style: { color: '#7FB5A8' } }}
								value={activeRestaurant?.name ?? ''}
								onChange={(evt) =>
									setActiveRestaurant({
										...activeRestaurant,
										name: evt.target.value
									})
								}
							/>


							<TextField
								label="Restaurant Id (click to copy)"
								variant="outlined"
								style={{ marginLeft: '1em' }}
								value={activeRestaurant?.id}
								onClick={() => {
									activeRestaurant?.id &&
                    navigator.clipboard.writeText(activeRestaurant.id);
								}}
								onFocus={() => (document.activeElement as HTMLElement).blur()}
							/>

							<DebugLogButton
								tooltip={false}
								log={activeRestaurant}
								style={{ opacity: 0.5 }}
							/>
						</Box>

						<TextField
							label="Landing Page Display Name"
							placeholder="Restaurant Display Name"
							variant="outlined"
							InputLabelProps={{ style: { color: '#7FB5A8' } }}
							value={activeRestaurant.displayName ?? ''}
							onChange={(evt) =>
								setActiveRestaurant({
									...activeRestaurant,
									displayName: evt.target.value
								})
							}
						/>
						<Box
							display='flex'
							flexDirection='row'
							style={{ gap: '16px', marginTop: '16px', marginBottom: '16px' }}
						>
							<InputLabel id="rms-format-label">RMS Format Override</InputLabel>
							<Select
								labelId="rms-format-label"
								style={{ minWidth: '200px' }}
								value={activeRestaurant?.rmsFormat || ''}
								onChange={(evt) => {
									const value = evt.target.value as TDR.RMSFormat | '';
									setActiveRestaurant({
										...activeRestaurant,
										rmsFormat: value === '' ? null : value
									});
								}}
								label="RMS Format"
							>
								<MenuItem value="">
									<em>None</em>
								</MenuItem>
								{Object.values(TDR.RMSFormat).map((format) => (
									<MenuItem key={format} value={format}>
										{format}
									</MenuItem>
								))}
							</Select>
							<Typography>
								{activeRestaurant.rmsFormat
									? TDR.RMSPreview[activeRestaurant.rmsFormat]
									: 'no preview available'}
							</Typography>
						</Box>
						<TextField
							label="Phone Number"
							placeholder="Phone Number"
							variant="outlined"
							InputLabelProps={{ style: { color: '#7FB5A8' } }}
							value={activeRestaurant?.phoneNumber ?? ''}
							onChange={(evt) =>
								setActiveRestaurant({
									...activeRestaurant,
									phoneNumber: evt.target.value
								})
							}
						/>
						<TextField
							label="Email"
							placeholder="Email"
							variant="outlined"
							InputLabelProps={{ style: { color: '#7FB5A8' } }}
							value={activeRestaurant?.contactEmail ?? ''}
							onChange={(evt) =>
								setActiveRestaurant({
									...activeRestaurant,
									contactEmail: evt.target.value
								})
							}
						/>
						<TextField
							label='Dinr Partner Link (Requires same day bookings flag enabled)'
							placeholder='Dinr Partner Link'
							variant='outlined'
							InputLabelProps={{ style: { color: '#7FB5A8' } }}
							value={activeRestaurant?.dinrPlatformLink ?? ''}
							onChange={(evt) => setActiveRestaurant({
								...activeRestaurant,
								dinrPlatformLink: evt.target.value
							})}
						/>
						<TextField
							label='Web Menu Link'
							placeholder='Web Menu Link'
							variant='outlined'
							InputLabelProps={{ style: { color: '#7FB5A8' } }}
							value={activeRestaurant?.menuUrl ?? ''}
							onChange={(evt) =>
								setActiveRestaurant({
									...activeRestaurant,
									menuUrl: evt.target.value
								})
							}
						/>
						<TextField
							label="Google Maps Directions Link"
							placeholder="Google Maps Directions Link"
							variant="outlined"
							InputLabelProps={{ style: { color: '#7FB5A8' } }}
							value={activeRestaurant?.directionsUrl ?? ''}
							onChange={(evt) =>
								setActiveRestaurant({
									...activeRestaurant,
									directionsUrl: evt.target.value
								})
							}
						/>

						<TextField
							label="Street Address"
							placeholder="123 example rd."
							variant="outlined"
							InputLabelProps={{ style: { color: '#7FB5A8' } }}
							value={activeRestaurant?.address ?? ''}
							onChange={(evt) =>
								setActiveRestaurant({
									...activeRestaurant,
									address: evt.target.value
								})
							}
						/>

						<TextField
							label="City"
							placeholder="city"
							variant="outlined"
							InputLabelProps={{ style: { color: '#7FB5A8' } }}
							value={activeRestaurant?.city ?? ''}
							onChange={(evt) =>
								setActiveRestaurant({
									...activeRestaurant,
									city: evt.target.value
								})
							}
						/>

						<TextField
							label="State/Province code"
							placeholder="eg. ON"
							variant="outlined"
							InputLabelProps={{ style: { color: '#7FB5A8' } }}
							value={activeRestaurant?.region ?? ''}
							onChange={(evt) =>
								setActiveRestaurant({
									...activeRestaurant,
									region: evt.target.value
								})
							}
						/>

						<TextField
							label="Postal Code"
							placeholder="eg. A1A 1A1 or 11111"
							variant="outlined"
							InputLabelProps={{ style: { color: '#7FB5A8' } }}
							value={activeRestaurant?.postalCode ?? ''}
							onChange={(evt) =>
								setActiveRestaurant({
									...activeRestaurant,
									postalCode: evt.target.value
								})
							}
						/>

						<InputLabel>
						          Country
						</InputLabel>
						<Select
							value={activeRestaurant?.country || ''}
							labelId="restaurantCountryLabel"
							className="select"
							variant="outlined"
							IconComponent={(props) => (
								<Box ml={1}>
									<KeyboardArrowDownIcon
										{...props}
										style={{ color: 'rgba(255, 255, 255, 0.87)' }}
									/>
								</Box>
							)}
							onChange={(evt) => {
								const country = evt.target.value as string;
								setActiveRestaurant(
									Object.assign({}, activeRestaurant, {
										country /*, currency */
									})
								);
							}}
						>
							{Object.values(TDR.Country).map((country) => (
								<MenuItem key={country} value={country}>
									{country}
								</MenuItem>
							))}
						</Select>

						<TextField
							label="Landing Page Display Address"
							placeholder="Restaurant Display Address"
							variant="outlined"
							InputLabelProps={{ style: { color: '#7FB5A8' } }}
							value={activeRestaurant.displayAddress ?? ''}
							onChange={(evt) =>
								setActiveRestaurant({
									...activeRestaurant,
									displayAddress: evt.target.value
								})
							}
						/>

						<InputLabel
							id="timezoneLabel"
							style={{
								marginLeft: '4px',
								marginRight: 'auto',
								paddingRight: '0.5em'
							}}
						>
              Timezone
						</InputLabel>
						<Select
							value={activeRestaurant?.timezone || ''}
							labelId="timezoneLabel"
							className="select"
							style={{ maxWidth: '250px' }}
							variant="outlined"
							IconComponent={(props) => (
								<Box ml={1}>
									<KeyboardArrowDownIcon
										{...props}
										style={{ color: 'rgba(255, 255, 255, 0.87)' }}
									/>
								</Box>
							)}
							error={!activeRestaurant.timezone}
							onChange={(evt) => {
								const timezone = evt.target.value;
								setActiveRestaurant(
									Object.assign({}, activeRestaurant, { timezone })
								);
							}}
						>
							{Object.values(TDR.Timezone).map((timezone) => (
								<MenuItem key={timezone} value={timezone}>
									{timezone}
								</MenuItem>
							))}
						</Select>
						{!activeRestaurant.timezone && (
							<FormHelperText>This field is required</FormHelperText>
						)}

						<Box display="flex" flexDirection="row" alignItems="left">
							<TextField
								label="Matterport Model ID"
								placeholder="Matterport Model ID"
								variant="outlined"
								style={{ width: '12em', marginRight: '0.5em' }}
								InputLabelProps={{ style: { color: '#7FB5A8' } }}
								value={activeRestaurant?.model ?? ''}
								onChange={(evt) =>
									setActiveRestaurant({
										...activeRestaurant,
										model: evt.target.value
									})
								}
							/>

							<Link
								href={`https://my.matterport.com/models/${activeRestaurant?.model}?organization=GXCJabK88qH`}
								target="_blank"
								disabled={!activeRestaurant?.model}
								component={Button}
								style={{ width: 'fit-content' }}
							>
								<img
									style={{ height: '3em' }}
									src="https://matterport.github.io/showcase-sdk/images/MP-logo_H_lock-RGB_white.svg"
								></img>
							</Link>
						</Box>
						<InputLabel
							id="gallery-opacity-slider"
							style={{
								marginLeft: '4px',
								marginRight: 'auto',
								paddingRight: '0.5em'
							}}
						>
              Widget & Landing Page Background Overlay Opacity
						</InputLabel>
						<Slider
							aria-labelledby="gallery-opacity-slider"
							valueLabelDisplay="auto"
							style={{
								maxWidth: '200px',
								marginLeft: '4px',
								marginRight: 'auto',
								paddingRight: '0.5em'
							}}
							step={0.05}
							min={0}
							max={1}
							value={activeRestaurant?.galleryOpacity ?? 0.8}
							onChange={(evt, value: number) =>
								setActiveRestaurant({
									...activeRestaurant,
									galleryOpacity: value
								})
							}></Slider>
						<InputLabel
							id="brightness-slider"
							style={{
								marginLeft: '4px',
								marginRight: 'auto',
								paddingRight: '0.5em'
							}}
						>
              Table Image Brightness (For table images displayed in carousels, checkout, and booking details)
						</InputLabel>
						<Slider aria-labelledby="brightness-slider" valueLabelDisplay="auto" style={{ maxWidth: '200px', marginLeft: '4px', marginRight: 'auto', paddingRight: '0.5em' }} step={0.05} min={0} max={5} marks={[{ value: 0, label: 'Dark' }, { value: 1, label: 'Normal' },{ value: 5, label: 'Bright' }]} value={activeRestaurant?.brightness ?? 1}
							onChange={(evt, value: number) =>
								setActiveRestaurant({
									...activeRestaurant,
									brightness: value
								})
							}></Slider>
						<InputLabel
							id="contrast-slider"
							style={{
								marginLeft: '4px',
								marginRight: 'auto',
								paddingRight: '0.5em'
							}}
						>
              Table Image Contrast (For table images displayed in carousels, checkout, and booking details)
						</InputLabel>
						<Slider aria-labelledby="contrast-slider" valueLabelDisplay="auto" style={{ maxWidth: '200px', marginLeft: '4px', marginRight: 'auto', paddingRight: '0.5em' }} step={0.05} min={0} max={2} marks={[{ value: 0, label: 'Light' }, { value: 1, label: 'Normal' },{ value: 2, label: 'Harsh' }]} value={activeRestaurant?.contrast ?? 1}
							onChange={(evt, value: number) =>
								setActiveRestaurant({
									...activeRestaurant,
									contrast: value
								})
							}></Slider>

						<Box display="flex" flexDirection="row" alignItems="left">
							<InputLabel
								id="currencyLabel"
								style={{ margin: 'auto', paddingRight: '0.5em' }}
							>
                Currency
							</InputLabel>
							<Select
								value={activeRestaurant?.currency || ''}
								labelId="currencyLabel"
								className="select"
								variant="outlined"
								IconComponent={(props) => (
									<Box ml={1}>
										<KeyboardArrowDownIcon
											{...props}
											style={{ color: 'rgba(255, 255, 255, 0.87)' }}
										/>
									</Box>
								)}
								onChange={(evt) => {
									const currency = evt.target.value;
									setActiveRestaurant(
										Object.assign({}, activeRestaurant, { currency })
									);
								}}
							>
								{Object.values(TDR.Currency).map((currency) => (
									<MenuItem key={currency} value={currency}>
										{currency}
									</MenuItem>
								))}
							</Select>
							<InputLabel
								id="countryLabel"
								style={{
									margin: 'auto',
									paddingRight: '0.5em',
									marginLeft: '1em',
									whiteSpace: 'nowrap'
								}}
							>
                Stripe Region
							</InputLabel>
							<Select
								value={activeRestaurant?.stripeRegion || ''}
								labelId="countryLabel"
								className="select"
								variant="outlined"
								IconComponent={(props) => (
									<Box ml={1}>
										<KeyboardArrowDownIcon
											{...props}
											style={{ color: 'rgba(255, 255, 255, 0.87)' }}
										/>
									</Box>
								)}
								onChange={(evt) => {
									const stripeRegion = evt.target.value as string;
									// const currency = TDR.REGIONS[stripeRegion].currency;
									setActiveRestaurant(
										Object.assign({}, activeRestaurant, {
											stripeRegion /*, currency */
										})
									);
								}}
							>
								{Object.values(TDR.StripeRegion)
									.filter((region) => TDR.REGIONS[region])
									.map((region) => (
										<MenuItem key={region} value={region}>
											{TDR.REGIONS[region].name}
										</MenuItem>
									))}
							</Select>
							<TextField
								label="Stripe Account"
								value={activeRestaurant?.accountId}
								placeholder=""
								variant="outlined"
								style={{ marginLeft: '1em' }}
								InputLabelProps={{ style: { color: '#7FB5A8' } }}
								onChange={(evt) =>
									setActiveRestaurant({
										...activeRestaurant,
										accountId: evt.target.value
									})
								}
							/>
						</Box>
						<TextField
							type={'number'}
							label="Grace Period (minutes)"
							value={activeRestaurant?.gracePeriod || DEFAULT_GRACE_PERIOD_MINUTES}
							style={{ maxWidth: '200px' }}
							fullWidth={false}
							onChange={(evt) =>
								setActiveRestaurant(
									Object.assign({}, activeRestaurant, {
										gracePeriod: evt.target.value
									})
								)
							}
						/>

						<Box display='flex' flexDirection='row' alignItems='center'>
							<FormControlLabel
								value="start"
								style={{ marginRight: '1em', whiteSpace: 'nowrap' }}
								control={<Switch
									checked={activeRestaurant.is_live}
									onChange={(evt) =>
										setActiveRestaurant({
											...activeRestaurant,
											is_live: evt.target.checked
										})
									}
									color='primary'
								/>}
								label='Restaurant is live'
								labelPlacement="start"
							/> </Box>

						<Box display="flex" flexDirection="row" alignItems="center">
							<FormControlLabel
								value="start"
								style={{ marginRight: '1em', whiteSpace: 'nowrap' }}
								control={<Switch
									checked={activeRestaurant?.limitAvailability}
									onChange={(evt) => setActiveRestaurant(Object.assign({}, activeRestaurant, { limitAvailability: evt.target.checked })) }
									color='primary'
								/>}
								label="limit bookable days in advance"
								labelPlacement="start"
							/>

							<TextField
								type={'number'}
								label="days in advance"
								disabled={!activeRestaurant?.limitAvailability}
								value={activeRestaurant?.bookableDaysInAdvance || DEFAULT_BOOKABLE_DAYS}
								onChange={(evt) =>
									setActiveRestaurant(
										Object.assign({}, activeRestaurant, {
											bookableDaysInAdvance: evt.target.value
										})
									)
								}
							/>
						</Box>
						<Box display="flex" flexDirection="row" alignItems="center">
							<FormControlLabel
								value="start"
								style={{ marginRight: '1em', whiteSpace: 'nowrap' }}
								control={
									<Switch
										checked={activeRestaurant?.allowNoShowFees}
										onChange={(evt) =>
											setActiveRestaurant(
												Object.assign({}, activeRestaurant, {
													allowNoShowFees: evt.target.checked
												})
											)
										}
										color="primary"
									/>
								}
								label="No-Show&nbsp;Fees"
								labelPlacement="start"
							/>

							<TextField
								label="No-Show Fee Disclaimer"
								disabled={!activeRestaurant?.allowNoShowFees}
								value={activeRestaurant?.feeDisclaimer || 'Failure to attend your scheduled reservation without cancelling {{#}} hours in advance will result in an additional no-show fee of {{$$}} per guest'}
								onChange={(evt) =>
									setActiveRestaurant(
										Object.assign({}, activeRestaurant, {
											feeDisclaimer: evt.target.value
										})
									)
								}
								multiline
							/>
						</Box>
						<Box display="flex" flexDirection="row" alignItems="center">
							<FormControlLabel
								value="start"
								style={{ marginRight: '1em', whiteSpace: 'nowrap' }}
								control={
									<Switch
										checked={activeRestaurant?.allowDepositFees}
										onChange={(evt) =>
											setActiveRestaurant(
												Object.assign({}, activeRestaurant, {
													allowDepositFees: evt.target.checked
												})
											)
										}
										color="primary"
									/>
								}
								label="Deposit&nbsp;Fees"
								labelPlacement="start"
							/>

							<TextField
								label="Deposit Fee Disclaimer"
								disabled={!activeRestaurant?.allowDepositFees}
								value={activeRestaurant?.depositFeeDisclaimer || 'All table booking fees include a {{$$}} deposit, per guest, collected at the time of booking and then deducted from the final bill at the restaurant.'}
								onChange={(evt) =>
									setActiveRestaurant(
										Object.assign({}, activeRestaurant, {
											depositFeeDisclaimer: evt.target.value
										})
									)
								}
								multiline
							/>
						</Box>
						<Box display="flex" flexDirection="row" alignItems="flex-end">
							<Box display="flex" flexGrow={1} mr={12}>
								<TextField
									label="Initial Matterport Balance"
									placeholder="600"
									variant="outlined"
									InputLabelProps={{ style: { color: '#7FB5A8' } }}
									value={activeRestaurant?.originalBalanceOwed ?? ''}
									onChange={(evt) =>
										setActiveRestaurant({
											...activeRestaurant,
											originalBalanceOwed: parseInt(evt.target.value || '0')
										})
									}
								/>

								<Box
									display="flex"
									flexDirection="row"
									style={{ alignSelf: 'center' }}
								>
									<InputLabel
										id="paidLabel"
										style={{
											margin: 'auto',
											paddingRight: '0.5em',
											marginLeft: '1em',
											whiteSpace: 'nowrap'
										}}
									>
                    Balance Owed Paid Back
									</InputLabel>
									<Switch
										checked={activeRestaurant?.isBalancePaidBack}
										onChange={() =>
											setActiveRestaurant({
												...activeRestaurant,
												isBalancePaidBack: !activeRestaurant?.isBalancePaidBack
											})
										}
										color="primary"
									/>
								</Box>
							</Box>
						</Box>
						<Box
							display="flex"
							flexDirection="row"
							alignItems="flex-end"
							style={{ marginBottom: '1em' }}
						>
							<Box display="flex" flexGrow={1} mr={2}>
								<PhoneEditor
									label="Notification Phone Numbers"
									numbers={activeRestaurant.notificationNumbers ?? []}
									onChange={(numbers: string[]) => {
										setActiveRestaurant(
											Object.assign({}, activeRestaurant, {
												notificationNumbers: numbers
											})
										);
									}}
								/>
							</Box>
						</Box>
						<Box display="flex" flexGrow={1} mr={2}>
							<TextField
								label="Custom Email Field"
								placeholder={'Enter anything you want to communicate to the guests of this restaurant in the confirmation emails'}
								value={activeRestaurant?.customEmailField || ''}
								onChange={(evt) =>
									setActiveRestaurant(
										Object.assign({}, activeRestaurant, {
											customEmailField: evt.target.value
										})
									)
								}
								multiline
							/>
						</Box>

						<h4>Partnerships: (Disabled)</h4>
						{/* <Box className="pill-toggle-box">
							<PillList
								mode="toggle"
								items={allPartnerships}
								labelKey={'name'}
								selected={
									activeRestaurant?.partnerships &&
                  activeRestaurant?.partnerships?.map((activePartner) =>
                  	allPartnerships.find((p) => p.id === activePartner.id)
                  )
								}
								onChange={(updatedPartners) => {
									setActiveRestaurant(
										Object.assign({}, activeRestaurant, {
											...activeRestaurant,
											partnerships: updatedPartners
										})
									);
								}}
							/>
						</Box> */}

						<ClosedDates
							activeRestaurant={activeRestaurant}
							setActiveRestaurant={setActiveRestaurant}
						/>
						<Box
							display="flex"
							alignItems="center"
							flex="1 0 auto"
							minWidth="0"
						>
							<div>
								<h4>Dietary Restrictions</h4>
								<DietaryOptions
									activeRestaurant={activeRestaurant}
									setActiveRestaurant={setActiveRestaurant}
								/>
							</div>
						</Box>

						<h4>Overrides</h4>
						<OverridesEditor
							label="Overrides"
							tables={tables}
							overrides={activeRestaurant.overrides}
							onChange={(overrides: TDR.Override[]) => {
								console.log(overrides);
								setActiveRestaurant(
									Object.assign({}, activeRestaurant, {
										overrides: overrides
									})
								);
							}}
						/>

						{!activeRestaurant['stupidHackDisableCutoffEditor'] && (
							<>
								<RestaurantCutoffsEditor
									restaurant={activeRestaurant}
									onChange={(cutoffs) =>
										setActiveRestaurant(
											Object.assign({}, activeRestaurant, {
												reservationCutoffs: cutoffs
											})
										)
									}
								/>
							</>
						)}

						<Box
							display="flex"
							flexDirection="row"
							justifyContent="space-between"
							width="100%"
							my={2}
						>
							<Box
								flexGrow="1"
								style={{ backgroundColor: '#333333', padding: '0.5em' }}
								mr={1}
							>
								<h4 style={{ margin: '0px', textAlign: 'center' }}>Taxes</h4>
								<PolicyGroupToggle
									policies={taxPolicies}
									group="tax"
									activeGroups={activeRestaurant.taxes || []}
									setGroup={setTax}
								/>
							</Box>

							<Box
								flexGrow="1"
								style={{ backgroundColor: '#333333', padding: '0.5em' }}
								ml={1}
							>
								<h4 style={{ margin: '0px', textAlign: 'center' }}>Payouts</h4>
								<PolicyGroupToggle
									policies={payoutPolicies}
									group="payout"
									activeGroups={activeRestaurant.payouts || []}
									setGroup={setPayout}
								/>
							</Box>
						</Box>

						{!activeRestaurant ? null : <PromoCodeEditor />}

						{!activeRestaurant ? null : (
							<PricingPolicyListEditor
								type={'item'}
								policies={restaurantPolicies}
								restaurant={activeRestaurant}
								setErrorMsg={setErrorMsg}
							/>
						)}
					</Box>
				)}
			</Box>
		</Container>
	);
};

AdminRestaurants.displayName = 'AdminRestaurantsComponent';

function PolicyGroupToggle({
	policies,
	group,
	activeGroups,
	setGroup
}: Props.PolicyGroupToggle) {
	return (
		<Box>
			{toPairs(groupBy(policies, (p) => p[group])).map(
				([groupId, policies]: [string, TDR.PricingPolicy[]]) => {
					const active = activeGroups.includes(groupId);
					policies = sortBy(policies, (p) => p.priority);

					return (
						<Box
							display="flex"
							flexDirection="row"
							width="100%"
							padding="1em"
							key={groupId}
						>
							<Box mr="auto" style={{ verticalAlign: 'middle' }}>
								{groupId}
								<Switch
									checked={active}
									onChange={() => setGroup(groupId, !active)}
									color="primary"
								/>
							</Box>
							<Box flexGrow="1" paddingTop="5px">
								{policies.map((policy) => (
									<Box
										key={policy.id}
										display="flex"
										flexDirection="row"
										alignItems="center"
										justifyContent="space-between"
									>
										<Box style={{ minWidth: '8em' }}>{policy.name}</Box>
										<Box display="flex" flexDirection="column">
											{policy.charges.map((charge, index) => (
												<ChargeLabel charge={charge} key={index} />
											))}
										</Box>
									</Box>
								))}
							</Box>
						</Box>
					);
				}
			)}
		</Box>
	);
}

function ChargeLabel({ charge }: { charge: TDR.PricingPolicy.Charge }) {
	const { amount, type } = charge;
	const percent = Math.round(amount * 10000) / 100;

	switch (type) {
		case 'add':
			return <Typography>{formatMonetaryValue(amount, null)}</Typography>;
		case 'per-guest':
			return <Typography>{formatMonetaryValue(amount, null)} /guest</Typography>;
		case 'multiply':
			return <Typography>{`${percent}`} %</Typography>;
		case 'multiply-subtotal':
			return <Typography>{`${percent}`} % subtotal</Typography>;
	}
}

function CutoffEditor({
	cutoffs,
	onChange = noop,
	disabled
}: Props.CutoffEditor) {
	const [state, setState] = useState(cutoffs);
	const cleanState = omitBy(state, isEmpty);

	useEffect(() => {
		if (disabled) {
			return;
		}
		if (!isEmpty(cleanState)) {
			onChange(cleanState);
		}
		else {
			onChange({});
		}
	}, [state]);

	return (
		<Box display="flex" flexDirection="row" alignItems="left">
			<Box width={'14em'}>
				<TimeBeforeEditor
					cutoffs={cutoffs}
					disabled={disabled}
					onChange={(tb) => setState({ ...state, create: tb })}
					cutoffType="create"
					label="Creation"
				/>
			</Box>

			<Box width={'14em'}>
				<TimeBeforeEditor
					cutoffs={cutoffs}
					disabled={disabled}
					onChange={(tb) => setState({ ...state, cancel: tb })}
					cutoffType="cancel"
					label="Cancellation"
				/>
			</Box>

			<Box width={'14em'}>
				<TimeBeforeEditor
					cutoffs={cutoffs}
					disabled={disabled}
					onChange={(tb) => setState({ ...state, update: tb })}
					cutoffType="update"
					label="Modification"
				/>
			</Box>

			{disabled ? null : (
				<Box flexGrow={2}>
					<Box display="flex" flexDirection="row" alignItems="left">
						<em>Condition</em>
						<ConditionHelpButton buttonAttrs={{ p: 0, ml: 1 }} />

						<DebugLogButton
							log={cleanState}
							attrs={{ p: 0 }}
							style={{ opacity: 0.5, marginLeft: 'auto' }}
						/>
					</Box>
					<ConditionEditor
						condition={cutoffs.condition || {}}
						onChange={(condition) => setState({ ...state, condition })}
					/>
				</Box>
			)}
		</Box>
	);
}

function TimeBeforeEditor({
	label,
	cutoffType,
	cutoffs,
	onChange,
	disabled
}: Props.TimeBeforeEditor) {
	const cutoff = cutoffs[cutoffType];

	const roundedBaseMarginHoursStr = isNumber(cutoff?.margin)
		? `${parseFloat((cutoff.margin / SECONDS_IN_AN_HOUR).toFixed(2))}`
		: '';

	const [marginStr, setMarginStr] = useState<string>(roundedBaseMarginHoursStr);
	const [margin, setMargin] = useState<number>();
	useEffect(() => {
		const marginSeconds = parseFloat(marginStr) * SECONDS_IN_AN_HOUR;
		if (isNaN(marginSeconds)) {
			setMargin(null);
		}
		else {
			setMargin(marginSeconds);
		}
	}, [marginStr]);

	const timeRx = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/;
	const [time, setTime] = useState<string>((cutoff?.time || '').trim());

	const [updatedCutoff, setUpdatedCutoff] = useState(cutoff);

	useEffect(() => {
		const validTime = time?.match(timeRx);
		const update = omitBy(
			{
				margin: margin,
				time: validTime ? time : null
			},
			isNull
		);

		if (!isEqual(update, updatedCutoff)) {
			setUpdatedCutoff(update);
			if (!disabled) {
				onChange(update);
			}
		}
	}, [margin, time]);

	return (
		<Box
			display="flex"
			flexDirection="column"
			alignItems="left"
			paddingLeft="1em"
			paddingRight="2em"
		>
			<em>{label}</em>

			<TextField
				label="Margin (hours)"
				variant="outlined"
				InputLabelProps={{ style: { color: '#7FB5A8' } }}
				disabled={disabled}
				value={marginStr}
				onChange={(evt) => setMarginStr(evt.target.value.trim())}
				error={!isEmpty(marginStr) && !parseFloat(marginStr)}
			/>

			<TextField
				label="Time (HH:MM, 24h)"
				variant="outlined"
				InputLabelProps={{ style: { color: '#7FB5A8' } }}
				disabled={disabled}
				value={time}
				onChange={(evt) => setTime(evt.target.value.trim())}
				error={!isEmpty(time) && !time.match(timeRx)}
			/>
		</Box>
	);
}

namespace AddRestaurantModal {
	export type Props = {
		onClose: () => void;
		onSave: (restaurant: Partial<TDR.Restaurant>) => void;
	};
}

const AddRestaurantModal = ({ onClose }: AddRestaurantModal.Props) => {
	const [name, setName] = useState<string>('');
	const [errorMsg, setErrorMsg] = useState<string>(null);

	const { firestore } = useContext(FirebaseContext);
	const { tagData, tourSnapshots, modelId, setModelId, sdk } =
    useContext(MatterportContext);

	useEffect(() => {
		if (isNull(sdk)) {
			setErrorMsg('Matterport SDK could not be initialized');
		}
		else if (!isEmpty(tagData) && !isEmpty(tourSnapshots)) {
			const invalidTags = tagData.filter(
				(tag) => !tourSnapshots.some((stop) => stop.name === tag.label)
			);
			setErrorMsg(
				!isEmpty(invalidTags)
					? `Tourstop not found for ${invalidTags.reduce(
						(accumulator, tag) => accumulator + tag.label + ', ',
						''
					)}.`
					: null
			);
		}
	}, [sdk, tagData, tourSnapshots]);

	const commit = async () => {
		const restaurantCollection = firestore.collection('Restaurants');
		const tablesCollection = firestore.collection('Tables');
		const featureFlagCollection = firestore.collection('FeatureFlags');

		const restaurantId = uuid();
		const restaurantRef = restaurantCollection.doc(restaurantId);
		const restaurantDoc = await restaurantRef.get();

		if (!restaurantDoc.exists) {
			restaurantRef.set({
				id: restaurantId,
				slug: slugify(name, { lower: true, strict: true }),
				name: name,
				displayName: name,
				model: modelId,
				phoneNumber: '',
				closed: {
					dates: [],
					weekdays: []
				},
				directionsUrl: '',
				media: [],
				menuUrl: '',
				website: '',
				promotionalContent: [],
				overrides: [],
				partnerships: []
			});

			const ffId = uuid();
			featureFlagCollection.doc(ffId).set({
				id: ffId,
				restaurantId: restaurantId,
				educationalComponents: false,
				flipLandingModal: false,
				newCheckout: true, // TODO: remove this feature flag now that all restaurants use v2 checkout
				patio: false,
				sameDayBooking: true
			});

			tagData.forEach((tag) => {
				const tableId = uuid();
				tablesCollection.doc(tableId).set({
					id: tableId,
					slug: slugify(tag.label, { lower: true, strict: true }),
					name: tag.label,
					description: '',
					defaultTimeSlots: {},
					minGuests: 2,
					maxGuests: 4,
					restaurantId: restaurantId,
					pos: {
						x: tag.anchorPosition.x + tag.stemVector.x,
						y: tag.anchorPosition.y + tag.stemVector.y,
						z: tag.anchorPosition.z + tag.stemVector.z
					}
				});
			});
		}
	};

	return (
		<Modal onClose={() => onClose()}>
			<Box>
				<h4>Add New Restaurant</h4>

				<TextField
					label="Restaurant Name"
					placeholder="Restaurant Name"
					variant="outlined"
					value={name}
					onChange={(evt) => setName(evt.target.value)}
				/>

				<Box display="flex" flexDirection="row">
					<TextField
						label="Matterport Model ID"
						placeholder="Matterport Model ID"
						variant="outlined"
						value={modelId}
						onChange={(evt) => setModelId(evt.target.value)}
					/>
				</Box>

				{modelId.length === 11 ? (
					<Box width="100%" height="150px">
						<Matterport />
					</Box>
				) : (
					<Box
						display="flex"
						width="100%"
						height="150px"
						alignItems="center"
						justifyContent="center"
					>
						<h4>Invalid Matterport Model ID</h4>
					</Box>
				)}

				{!isNull(errorMsg) && <Box color="#e74c3c">{errorMsg}</Box>}

				<Button
					variant="contained"
					color="primary"
					disabled={
						isEmpty(name) ||
            modelId.length !== 11 ||
            !isNull(errorMsg) ||
            isEmpty(tagData)
					}
					onClick={() => commit().then(() => onClose())}
				>
          Add
				</Button>
			</Box>
		</Modal>
	);
};

export default AdminRestaurants;

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import ReactMarkdown from 'react-markdown';
import { PricingPolicyListEditor } from './PricingPolicyEditor';
import DebugLogButton from './DebugLogButton';
import RestaurantSelector from './RestaurantSelector';
// import PillList from './PillList';
import DEFAULT_BOOKABLE_DAYS = TDR.DEFAULT_BOOKABLE_DAYS;
import DEFAULT_GRACE_PERIOD_MINUTES = TDR.DEFAULT_GRACE_PERIOD_MINUTES;

function RestaurantCutoffsEditor({
	restaurant,
	onChange
}: Props.CutoffsArrayEditor) {
	const [showHelp, setShowHelp] = useState(false);

  type ExtCutoff = TDR.ReservationCutoffs & { id: string };
  const ext = (c: TDR.ReservationCutoffs) =>
  	({ ...c, id: c['id'] || uuid() } as ExtCutoff);
  const unExt = (c: ExtCutoff) => omit(c, ['id']) as TDR.ReservationCutoffs;

  const [cutoffs, setCutoffs] = useState<ExtCutoff[]>(
  	restaurant?.reservationCutoffs?.map(ext) || []
  );
  useEffect(() => {
  	console.log('RestaurantCutoffsEditor', {
  		cutoffs,
  		filtered: cutoffs.filter((c) => !isNull(c)),
  		stripped: cutoffs.filter((c) => !isNull(c)).map(unExt)
  	});

  	onChange(cutoffs.filter((c) => !isNull(c)).map(unExt));
  }, [cutoffs]);

  function onDragEnd({ source, destination }) {
  	if (destination && !isEqual(destination, source)) {
  		const result = Array.from(cutoffs);
  		const [target] = result.splice(source.index, 1);
  		result.splice(destination.index, 0, target);

  		setCutoffs(result);
  	}
  }

  return (
  	<>
  		<Box display="flex" flexDirection="row">
  			<h4>Cutoffs</h4>

  			<IconButton
  				onClick={() => {
  					setCutoffs([...cutoffs, { id: uuid() }]);
  				}}
  			>
  				<AddIcon />
  			</IconButton>

  			<IconButton onClick={() => setShowHelp(true)}>
  				<Help />
  			</IconButton>
  		</Box>
  		<DragDropContext onDragEnd={onDragEnd}>
  			<Droppable droppableId="cutoffs">
  				{(provided) => (
  					<RootRef rootRef={provided.innerRef}>
  						<List style={{ backgroundColor: 'black' }}>
  							{cutoffs.map((cutoffsObj, index) => (
  								<Draggable
  									key={cutoffsObj.id}
  									draggableId={cutoffsObj.id}
  									index={index}
  								>
  									{(provided) => (
  										<ListItem
  											ref={provided.innerRef}
  											{...provided.draggableProps}
  											style={{
  												...provided.draggableProps.style,
  												backgroundColor: '#333333',
  												display: 'flex',
  												alignItems: 'flex-start',
  												borderBottom: '3px solid #232323'
  											}}
  										>
  											<ListItemIcon
  												{...provided.dragHandleProps}
  												style={{
  													...provided.dragHandleProps.style,
  													minWidth: '0px',
  													paddingTop: '1em',
  													paddingBottom: '1em'
  												}}
  											>
  												<DragHandle />
  											</ListItemIcon>

  											<Box style={{ flexGrow: 1 }}>
  												<CutoffEditor
  													cutoffs={cutoffsObj}
  													onChange={(update) => {
  														const id = cutoffsObj.id;
  														const index = cutoffs.findIndex(
  															(c) => c.id === id
  														);
  														const cutoffsCopy = [...cutoffs];
  														cutoffsCopy[index] = {
  															...update,
  															id
  														} as ExtCutoff;

  														if (
  															index !== -1 &&
                                !isEqual(cutoffsCopy, cutoffs)
  														) {
  															setCutoffs(cutoffsCopy);
  														}
  													}}
  												/>
  											</Box>

  											<IconButton
  												style={{
  													paddingTop: '1em',
  													paddingBottom: '1em'
  												}}
  												onClick={() => {
  													setCutoffs(
  														cutoffs.filter((c) => c.id !== cutoffsObj.id)
  													);
  												}}
  											>
  												<DeleteIcon />
  											</IconButton>
  										</ListItem>
  									)}
  								</Draggable>
  							))}
  							{provided.placeholder}
  						</List>
  					</RootRef>
  				)}
  			</Droppable>

  			<Box display="flex" flexDirection="row" alignItems="center" mt={1}>
  				<CutoffEditor
  					cutoffs={TDR.DEFAULT_RESERVATION_CUTOFFS}
  					disabled={true}
  				/>
          (Default)
  			</Box>
  		</DragDropContext>

  		<Modal
  			open={showHelp}
  			onClose={() => setShowHelp(false)}
  			styleOverride={{ width: '80%', height: '80%', maxWidth: '80%' }}
  			title={'Reservation Cutoffs'}
  		>
  			<ReactMarkdown className="markdown">{cutoffsHelpDoc}</ReactMarkdown>
  		</Modal>
  	</>
  );
}
