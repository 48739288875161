import {
	Box,
	Collapse,
	Container,
	FormControl,
	InputLabel,
	MenuItem,
	Select as MuiSelect,
	Select,
	Snackbar,
	Switch,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField
} from '@material-ui/core';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import React, { useContext, useEffect, useState } from 'react';
import { TDR } from 'tdr-common';
import { updateRestaurantFeatureFlag } from '../api';
import getFeatureFlagMap from '../api/getFeatureFlagMap';
import { FirebaseContext } from '../context/FirebaseContext';
import { RestaurantContext } from '../context/RestaurantContext';
import isEmpty from 'lodash/isEmpty';

const DISABLED_FLAGS: TDR.FeatureKey[] = ['newCheckout'];

const SUCCESS_MSG = 'Successfully updated feature flag value.';

// const LANDING_COPY_OPTIONS = [
// 	{
// 		value: 'B1',
// 		label: 'B1 - I\'ll choose where I sit. | It\'s ok, sit me anywhere.'
// 	},
// 	{ value: 'B2', label: 'B2 - Premium Seating | Standard Seating' }
// ];

function Alert(props: AlertProps) {
	return <MuiAlert elevation={6} variant='filled' {...props} />;
}

type RestaurantFilter = 'all' | 'active';

const AdminFeatureFlagsGlobal = () => {
	const { firestore } = useContext(FirebaseContext);
	const { restaurants } = useContext(RestaurantContext);

	const [showDefinitions, setShowDefinitions] = useState(false);
	const [message, setMessage] = useState<string>(null);
	const [restaurantFilter, setRestaurantFilter] = useState<RestaurantFilter>('active');

	// Map of restaurantId to feature flags
	const [features, setFeatures] = useState<Record<string, TDR.FeatureFlags>>({});

	useEffect(() => {
		let isMounted = true;
		if (!firestore) {
			return;
		}

		getFeatureFlagMap(firestore).then((featuresMap) => {
			if (isMounted) {
				setFeatures(featuresMap);
			}
		});
		return () => {
			isMounted = false;
		};
	}, [firestore]);

	const handleFlagSimpleChange = async (restaurantId: string, flag: TDR.FeatureKey, value: TDR.FeatureFlagSimple) => {
		const updates = {
			...features,
			[restaurantId]: {
				...features[restaurantId],
				[flag]: value
			}
		};

		updateRestaurantFeatureFlag(firestore, restaurantId, flag, value).then(() => {
			setFeatures(updates);
			setMessage(SUCCESS_MSG);
		});
	};

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const handleFlagWithOptionsChange = (
		restaurantId: string,
		flag: TDR.FeatureKey,
		value: TDR.FeatureFlagWithOptions<any>
	) => {
		const { enabled, options } = value;
		updateRestaurantFeatureFlag(firestore, restaurantId, flag, {
			enabled,
			options
		}).then(() => {
			setFeatures({
				...features,
				[restaurantId]: {
					...features[restaurantId],
					[flag]: { enabled, options }
				}
			});
			setMessage(SUCCESS_MSG);
		});
	};

	const columnNamesSimpleFlags: TDR.FeatureKey[] = [
		'premiumChooseCopyExperiment',
		'flipLandingModal',
		'enableFastCheckout',
		'patio',
		'sameDayBooking',
		'educationalComponents',
		// The hideFreePricing flag was created for Coya Dubai demo purposes, hiding from UI for now to avoid accidental toggling ON at other restaurants
		// 'hideFreePricing',
		'enableAddOns',
		'autoSelectGroupSize',
		'ctaExperiment',
		'privateEvents'
	];

	const columnNamesFlagsWithOptions: TDR.FeatureKey[] = ['largeGroupInbounds'];

	return (
		<Container maxWidth='xl'>
			{message && (
				<Snackbar
					open={!!message}
					autoHideDuration={6000}
					onClose={() => setMessage('')}
					anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
				>
					<Alert onClose={() => setMessage('')} severity='success'>
						{message}
					</Alert>
				</Snackbar>
			)}
			<Box display='flex' flexDirection='row' width='100%' marginTop='50px'>
				<h2>Feature Flags</h2>
				<Box flexGrow={1}></Box>
				<FormControl variant='filled' className='select-filter'>
					<InputLabel id='booking-status-filter'>Restaurant Status</InputLabel>
					<MuiSelect
						labelId='booking-status-filter'
						value={restaurantFilter}
						onChange={(evt) => setRestaurantFilter(evt.target.value as RestaurantFilter)}
					>
						<MenuItem value={'all' as RestaurantFilter}>All</MenuItem>
						<MenuItem value={'active' as RestaurantFilter}>Active</MenuItem>
					</MuiSelect>
				</FormControl>
			</Box>

			<h4
				style={{ cursor: 'pointer', margin: '8px 0' }}
				onClick={() => setShowDefinitions((isShowing) => !isShowing)}
			>{`Feature definitions (click to ${showDefinitions ? 'hide' : 'expand'})`}</h4>

			<Collapse in={showDefinitions}>
				<TableContainer style={{ marginBottom: '40px' }}>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell style={{ fontWeight: 'bold' }}>Feature</TableCell>
								<TableCell style={{ fontWeight: 'bold' }}>Description</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{Object.entries(TDR.FeatureFlagString).map(([feature, description]) => (
								<TableRow key={feature}>
									<TableCell>{feature}</TableCell>
									<TableCell>{description}</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</Collapse>

			<Table stickyHeader>
				<TableHead>
					<TableRow>
						<TableCell
							style={{
								position: 'sticky',
								left: 0,
								backgroundColor: '#121212',
								borderRight: '1px solid white',
								zIndex: 1001
							}}
						>
              Restaurant
						</TableCell>
						{[...columnNamesFlagsWithOptions, ...columnNamesSimpleFlags].map((name) => (
							<TableCell key={name} align='center'>
								{name}
							</TableCell>
						))}
					</TableRow>
				</TableHead>
				<TableBody>
					{restaurants
						.filter((r) => restaurantFilter === 'all' || (restaurantFilter === 'active' && r.is_live === true))
						.map((restaurant) => (
							<TableRow key={restaurant.id}>
								<TableCell
									style={{
										position: 'sticky',
										left: 0,
										backgroundColor: '#121212',
										borderRight: '1px solid white',
										zIndex: 1000
									}}
								>
									{restaurant.name}
								</TableCell>

								<TableCell style={{ alignItems: 'center', gap: '8px', height: 370 }}>
									<FeatureFlagSwitch
										flagKey={'largeGroupInbounds'}
										value={features[restaurant.id]?.largeGroupInbounds?.enabled ?? true}
										onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
											handleFlagWithOptionsChange(restaurant.id, 'largeGroupInbounds', {
												enabled: evt.target.checked,
												options: features[restaurant.id]?.largeGroupInbounds?.options || {}
											})
										}
									/>

									<Box
										style={{
											display: 'flex',
											flexDirection: 'column',
											gap: '8px',
											alignItems: 'flex-start'
										}}
									>
										<FeatureFlagDropdown
											label='Variant'
											labelId='large-group-inbounds-version-label'
											disabled={false}
											value={features[restaurant.id]?.largeGroupInbounds?.options?.version || 'direct'}
											options={[
												{ value: 'direct', label: 'direct' },
												{ value: 'form', label: 'form' }
											]}
											onChange={(evt: React.ChangeEvent<{ value: any }>) =>
												handleFlagWithOptionsChange(restaurant.id, 'largeGroupInbounds', {
													enabled: features[restaurant.id]?.largeGroupInbounds?.enabled,
													options: {
														...features[restaurant.id]?.largeGroupInbounds?.options,
														version: evt.target.value
													}
												})
											}
										/>

										<FeatureFlagTimeInput
											label='Start Time'
											labelId='large-group-inbounds-start-label'
											disabled={!features[restaurant.id]?.largeGroupInbounds?.enabled}
											value={features[restaurant.id]?.largeGroupInbounds?.options?.startTime?.toString() || ''}
											onChange={(evt: React.ChangeEvent<{ value: string }>) =>
												handleFlagWithOptionsChange(restaurant.id, 'largeGroupInbounds', {
													enabled: features[restaurant.id]?.largeGroupInbounds?.enabled,
													options: {
														...features[restaurant.id]?.largeGroupInbounds?.options,
														startTime: evt.target.value
													}
												})
											}
										/>
										<FeatureFlagTimeInput
											label='End Time'
											labelId='large-group-inbounds-end-label'
											disabled={!features[restaurant.id]?.largeGroupInbounds?.enabled}
											value={features[restaurant.id]?.largeGroupInbounds?.options?.endTime?.toString().trim() || ''}
											onChange={(evt: React.ChangeEvent<{ value: string }>) =>
												handleFlagWithOptionsChange(restaurant.id, 'largeGroupInbounds', {
													enabled: features[restaurant.id]?.largeGroupInbounds?.enabled,
													options: {
														...features[restaurant.id]?.largeGroupInbounds?.options,
														endTime: evt.target.value
													}
												})
											}
										/>
										<FeatureFlagTextInput
											label='Custom Form Description'
											labelId='large-group-inbounds-description-label'
											disabled={!features[restaurant.id]?.largeGroupInbounds?.enabled}
											value={
												features[restaurant.id]?.largeGroupInbounds?.options?.formDescription?.toString() || undefined
											}
											onChange={(evt: React.ChangeEvent<{ value: string }>) =>
												handleFlagWithOptionsChange(restaurant.id, 'largeGroupInbounds', {
													enabled: features[restaurant.id]?.largeGroupInbounds?.enabled,
													options: {
														...features[restaurant.id]?.largeGroupInbounds?.options,
														formDescription: evt.target.value.trim()
													}
												})
											}
										/>
									</Box>
								</TableCell>

								{/* Simple feature flags (no options) */}
								{columnNamesSimpleFlags.map((featureKey) => {
									const featureValue = features[restaurant.id]?.[featureKey] ?? false;
									if (typeof featureValue !== 'boolean') {
										return;
									}
									return (
										<TableCell key={featureKey} align='center'>
											<FeatureFlagSwitch
												flagKey={featureKey}
												value={featureValue}
												onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
													handleFlagSimpleChange(restaurant.id, featureKey, evt.target.checked)
												}
											/>
										</TableCell>
									);
								})}
								{/* End of Simple feature flags (no options) */}
							</TableRow>
						))}
				</TableBody>
			</Table>
		</Container>
	);
};
export default AdminFeatureFlagsGlobal;

type FeatureFlagSwitchProps = {
	flagKey: TDR.FeatureKey;
	value: boolean;
	onChange: (evt: React.ChangeEvent<HTMLInputElement>) => void;
};
const FeatureFlagSwitch = ({ flagKey, value, onChange }: FeatureFlagSwitchProps) => {
	return <Switch checked={value} disabled={DISABLED_FLAGS.includes(flagKey)} onChange={onChange} color='primary' />;
};

type FeatureFlagDropdownProps = {
	label: string;
	labelId: string;
	disabled: boolean;
	value: string;
	onChange: (evt: React.ChangeEvent<{ value: string }>) => void;
	options: { value: string; label: string }[];
};
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const FeatureFlagDropdown = ({ label, labelId, disabled, value, onChange, options }: FeatureFlagDropdownProps) => {
	return (
		<FormControl style={{ minWidth: '130px', maxWidth: '400px' }}>
			<InputLabel id={labelId}>{label}</InputLabel>
			<Select labelId={labelId} value={value} disabled={disabled} onChange={onChange}>
				{options.map(({ value, label }) => (
					<MenuItem key={value} value={value}>
						{label}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
};

const FeatureFlagTimeInput = ({
	label,
	labelId,
	disabled,
	value,
	onChange
}: Omit<FeatureFlagDropdownProps, 'options'>) => {
	const [time, setTime] = useState(value);

	const timeRx = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/;
	return (
		<FormControl style={{ minWidth: '130px', maxWidth: '400px' }}>
			<InputLabel id={labelId}>{label}</InputLabel>
			<TextField
				label='Time (HH:MM, 24h)'
				variant='outlined'
				InputLabelProps={{ style: { color: '#7FB5A8' } }}
				disabled={disabled}
				value={time || value}
				onChange={(evt) => setTime(evt.target.value.trim())}
				onBlur={onChange}
				error={!isEmpty(time) && !time.match(timeRx)}
			/>
		</FormControl>
	);
};

const FeatureFlagTextInput = ({
	label,
	labelId,
	disabled,
	value,
	onChange
}: Omit<FeatureFlagDropdownProps, 'options'>) => {
	const [text, setText] = useState(value);

	return (
		<FormControl style={{ minWidth: '130px', maxWidth: '400px' }}>
			<InputLabel id={labelId}>{label}</InputLabel>
			<TextField
				label='description'
				variant='outlined'
				InputLabelProps={{ style: { color: '#7FB5A8' } }}
				disabled={disabled}
				value={text ?? value}
				onChange={(evt) => setText(evt.target.value)}
				onBlur={onChange}
			/>
		</FormControl>
	);
};
